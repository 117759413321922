<template>
  <div class="admin-stocks-listing">
    <title-bar addBtn :addUrl="getAddStockURL()" title="Warehouses" />
    <vue-good-table
      class="custom-table-style"
      mode="remote"
      :totalRows="pages"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="stocks"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        setCurrentPage: currentPage,
      }"
      @on-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span
          class="d-flex align-items-center"
          :class="props.column.field"
          v-if="props.column.field == 'telephone'"
        >
          {{ props.formattedRow[props.column.field] }}
          <call-btn
            class="ml-2"
            v-if="props.formattedRow[props.column.field]"
            :number="props.formattedRow[props.column.field]"
          />
        </span>

        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'address'"
        >
          {{ `${props.formattedRow[props.column.field].streetAddress} ${props.formattedRow[props.column.field].streetNumber ? props.formattedRow[props.column.field].streetNumber : ''}` }}
        </span>

        <span class="buttons" v-else-if="props.column.field == 'id'">
          <div class="just-buttons">
            <edit-btn
              :path="getEditStockURL(props.formattedRow[props.column.field])"
            />
            <delete-btn @pressDelete="deleteStock(props.row['@id'])" />
          </div>
        </span>
        <span :class="props.column.field" v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import TitleBar from '@/components/TitleBar.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import CallBtn from '@/components/Buttons/CallBtn.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import Toast from '@/components/Toast.vue'

export default {
  name: 'AdminStocksListing',
  components: {
    TitleBar,
    VueGoodTable,
    TablePagination,
    CallBtn,
    EditBtn,
    DeleteBtn,
  },
  data() {
    return {
      isLoading: false,
      stocks: null,
      perPage: 15,
      currentPage: 1,
      pages: 0,

      columns: [
        {
          field: 'name',
          sortable: false,
        },
        {
          field: 'telephone',
          sortable: false,
        },
        {
          field: 'email',
          sortable: false,
        },
        {
          field: 'address',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    loadStocks() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      }
      this.$Warehouses.getCollection({ params }, 'warehouse_stock_list').then(response => {
        this.pages = response.data['hydra:totalItems']
        this.stocks = response.data['hydra:member']
      })
    },
    deleteStock(url) {
      this.$Warehouses
        .deleteResourceByUrl({ url })
        .then(response => {
          if (response.status === 204) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Stock deleted',
              '',
              'success',
            )
            this.loadStocks()
          }
        })
        .catch(error => {
          if (error.response) {
            this.$emit('clearAction')
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
        })
    },
    onPageChange() {
      this.loadStocks()
    },
    getAddStockURL() {
      return `/add-warehouse`
    },
    getEditStockURL(id) {
      return `/warehouses/${id}/edit`
    },
  },
}
</script>
